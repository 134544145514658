@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(6) 0 0;
}

.pd-item,
.pd-item-secondary {
  background: var.$color-neutral-white;
  align-items: center;
  padding: 0 fn.spacing(2);
  justify-content: center;
}

.pd-item-secondary {
  background: var.$color-primary-highlight-blue2;
}

.pd-item-eyebrow {
  text-align: left;
  padding-bottom: 0;
}

.pd-item-list {
  padding: 0;
  margin: 0;
}

.pd-item-panel-left {
  padding: fn.spacing(6) 0 0 0;
  max-width: 500px;
}

.pd-item-panel-right {
  padding: 0 0 fn.spacing(6) 0;
  max-width: 500px;
}

.pd-item-brand,
.pd-item-info {
  display: flex;
  flex-direction: column;
  gap: fn.spacing(2);
  max-width: 390px;
  margin: 0 auto;
  position: relative;
}

.pd-item-brand {
  gap: 0;
}

.pd-item-image {
  border-radius: fn.spacing(1);
}

.pd-item-card-image {
  position: relative;
  margin-top: -70px;
}

.pd-item-list-text {
  line-height: 0;
}

.sticky-tabs {
  margin-top: fn.spacing(4);
}

.pd-eye-brow {
  font-size: 14px;
  letter-spacing: 3.5px;
  padding-bottom: fn.spacing(1);
}

.pd-item-no-secondary-image .pd-item-panel-left {
  padding: fn.spacing(4) 0;
}

@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8) 0 0 0;
  }

  .pd-item,
  .pd-item-secondary {
    padding: fn.spacing(8) 0 0 0;
    justify-content: inherit;
    min-height: fn.spacing(50);
  }

  .pd-item-no-secondary-image {
    padding: fn.spacing(8) 0;
  }

  .pd-item-card-image {
    margin-top: -200px;
    width: 130%;
    right: -70px;
  }

  .pd-item-secondary .pd-item-card-image {
    left: -170px;
  }

  .pd-item-panel-left,
  .pd-item-panel-right {
    padding: 0;
    max-width: initial;
  }

  .pd-item-panel-left {
    .pd-item-brand {
      padding-right: 50px;
    }
  }

  .pd-item-secondary .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 50px;
      padding-right: 0;
    }
  }

  .pd-eye-brow {
    padding-bottom: fn.spacing(2);
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .pd-item-secondary .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.backgroud-color-primary-highlight-blue2 {
  background: var.$color-primary-highlight-blue2;
}

.backgroud-color-neutral-background-default {
  background: var.$color-neutral-background-default;
}
